/* Returns the iframe element in the parent document that contains the document this function was called from */
export const getIframeInParentDoc = () => {
  if (!window.parent || window.parent == window)
    throw new Error('Can not locate parent window')

  const iframe = Array.from(
    window.parent.document.querySelectorAll('iframe')
  ).find((iframe) => iframe.contentDocument == document)

  if (!iframe) throw new Error('Can not locate iframe container')

  return iframe
}
